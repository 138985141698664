//Tiles

.square {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    &:not(.contain) {
    	@extend .bg-cover;
    }
    &.contain {
    	background-color: $white;
    	background-size: contain;
    	background-position: center;
    	background-repeat: no-repeat;
    }
    &.tall{
    	padding-bottom: 112.5%;
        @include breakpoint(large){
            padding-bottom: 116.667%;
        }
        @include breakpoint(xlarge){
            padding-bottom: 124%;
        }
    }
    &.wide {
        padding-bottom: 75%;
        @include breakpoint(large){
            padding-bottom: 66%;
        }
        @include breakpoint(xlarge){
            padding-bottom: 57%;
        }
    }
    &.extra-wide {
        padding-bottom: 50%;
        @include breakpoint(large){
            padding-bottom: 33%;
        }
    }
}

.border-tiles {
	border: 2px solid $white;
	.tile {
		-webkit-box-shadow: inset 0px 0px 0px 2px $white;
		-moz-box-shadow: inset 0px 0px 0px 2px $white;
		box-shadow: inset 0px 0px 0px 2px $white;
	}
}

// .border-tiles {
// 	>.column {
// 		border-bottom: 4px solid $white;
// 		@include breakpoint(medium){
// 			&:nth-child(1),
// 			&:nth-child(3) {
// 				// border-right: 2px solid $white;
// 				-webkit-box-shadow: inset -2px 0px 0px 0px rgba(255,255,255,1);
// 				-moz-box-shadow: inset -2px 0px 0px 0px rgba(255,255,255,1);
// 				box-shadow: inset -2px 0px 0px 0px rgba(255,255,255,1);
// 			}
// 			&:nth-child(2),
// 			&:nth-child(4) {
// 				// border-left: 2px solid $white;
// 				-webkit-box-shadow: inset 2px 0px 0px 0px rgba(255,255,255,1);
// 				-moz-box-shadow: inset 2px 0px 0px 0px rgba(255,255,255,1);
// 				box-shadow: inset 2px 0px 0px 0px rgba(255,255,255,1);
// 			}
// 		}
// 		@include breakpoint(large){
// 			&:first-child {
// 				-webkit-box-shadow: inset -2px 2px 0px 0px rgba(255,255,255,1);
// 				-moz-box-shadow: inset -2px 2px 0px 0px rgba(255,255,255,1);
// 				box-shadow: inset -2px 2px 0px 0px rgba(255,255,255,1);
// 			}
// 			&:nth-child(2) {
// 				// border-bottom: 2px solid $white;
// 				-webkit-box-shadow: inset 2px 2px 0px 0px rgba(255,255,255,1);
// 				-moz-box-shadow: inset 2px 2px 0px 0px rgba(255,255,255,1);
// 				box-shadow: inset 2px 2px 0px 0px rgba(255,255,255,1);
// 			}
// 			&:nth-child(3),
// 			&:nth-child(4) {
// 				// border-top: 2px solid $white;
// 				-webkit-box-shadow: inset 0px -2px 0px 0px rgba(255,255,255,1);
// 				-moz-box-shadow: inset 0px -2px 0px 0px rgba(255,255,255,1);
// 				box-shadow: inset 0px -2px 0px 0px rgba(255,255,255,1);
// 			}
// 		}
// 	}
// }

.tile {
	@extend .bg-cover,
			.relative;
	.title {
		@extend .color-white, .margin-m;
	}
	&:before {
		display: none;
		content: '';
		position: absolute;
		@include positions(0,0,0,0);
		background-color: rgba($theme, 0.8);
		@include breakpoint(large){
			display: block;
		}
	}
	@media (max-width: 1024px){
		&:before {
			display: none;
		}
	}
	&:before,
	.hover {
		width: 100%;
		opacity: 1;
		@include transitions("opacity 0.3s");
		@include breakpoint(large){
			opacity: 0;
		}
	}
	&:hover {
		&:before,
		.hover {
			opacity: 1;
		}
	}
	.hover {
		@extend	.display-block,
				.padding-l;
		left: 0;
		bottom: 0;
		z-index: 50;
		&.absolute {
			background-color: rgba($theme, 0.8);
		}
		@include breakpoint(large){
			&.absolute {
				bottom: 50%;
				@include transforms('translateY(50%)');
				background-color: $transparent;
			}
		}
	}
	.button {
		text-align: center;
		background-color: $transparent;
		font-size: 12px;
		@include breakpoint(medium){
			font-size: 15px;
		}
	}
}

.project,
.structure,
.service,
.equipment {
	.tile {
		.hover {
			background-color: rgba($theme, 0.8);
			@include breakpoint(large){
				background-color: rgba($theme, 0);
			}
		}
	}
}

.tile:not(.square){

	.hover {
		position: relative;
	}
}

//Buttons

.button {
    @extend .display-inline-block, .weight-bold, .uppercase;
    border-width: 4px;
    border-style: solid;
    white-space: nowrap;
    padding: 15px 30px;
    min-width: 200px;
    &.button-white {
        background-color: $white;
        border-color: $theme;
        @extend .color-theme;
    }
    &.button-theme {
        background-color: $theme;
        border-color: $white;
        @extend .color-white;
    }
    &.button-green {
        background-color: $transparent;
        border-color: $theme;
        @extend .color-theme;
    }
}

.play-btn {
    @extend .absolute-center,
            .display-block;
    height: 40px;
    width: 40px;
    background-color: rgba($white, 0.8);    
    border-radius: 50%;
    &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 20px;
        border-color: transparent transparent transparent #808080;
        margin-left: 2.5px;
        @extend .absolute-center,
                .display-block;
    }
    @include breakpoint(large){
        height: 80px;
        width: 80px;
        &:after {
            border-width: 20px 0 20px 30px;
            margin-left: 5px;
        }
    }
}
//Footer

.offers {
    @include scale(padding-top, 240);
    @include scale(padding-bottom, 240);
    @extend .color-white;
    @include breakpoint(medium){
        @include scale(padding-top, 120);
        @include scale(padding-bottom, 120);
    }
    .offer {
        background-color: rgba($black, 0.9);
    }
    h4 {
        margin-bottom: 20px;
    }
    .button {
        text-align: center;
    }
}
.pre-footer {
    @include scale(padding-top, 120);
    @include scale(padding-bottom, 120);
    @extend .bg-theme,
            .color-white;
    @include breakpoint(medium){
        @include scale(padding-top, 30);
        @include scale(padding-bottom, 30);
    }
    .headings {
        @extend .relative,
                .text-center;
        @include breakpoint(medium){
            text-align: left;
        }
    }
    .call-to-action {
        @include breakpoint(medium){
            position: absolute;
            top: 50%;
            @include transforms('translateY(-50%)');
            right: 0;
            text-align: center;
            .button {
                float: right;
            }
        }
    }
}
footer {
    @extend .bg-black;
    nav {
        @extend .vert-padding-xs;
        ul {
            @extend .stack,
                    .margin-gutter;
            .heading {
                @extend .weight-bold,
                        .color-theme,
                        .font-franklin;
            }
        }
        .contact-details {
            @extend .weight-bold;
        }
    }
    .fine-print {
        opacity: 0.5;
        font-size: 0.8rem;
    }
}
//About

.about-page {
    section>.sub,
    section>.main-title {
        @extend .row-gutters;
    }
    .main-title {
        @extend .color-theme,
                .margin-s;
    }
    .summary-text {
    	@extend .show-for-large;
    }
    .main-image {
        min-height: 50vw;
        @include breakpoint(xlarge){
            min-height: 0;
        }
    }
    [data-slick-slides] {
        .slide {
            @extend .row-gutters;
        }
        .slick-dots {
            li:not(.slick-active) {
                background-color: $theme-dark;
                opacity: 0.25;
            }
        }
    }
}

.view-cert.icon-before {
    background-image: url('../img/icons/zoom.jpg');
    background-size: auto 80%;
}

[download].icon-before,
[download].icon-after {
    background-image: url('../img/icons/download.jpg');
    background-size: auto 80%;
}
// Typography

//Font Weights

$font-weight-map: (
    'light': 300,
    'regular': 500,
    'semi-bold': 600,
    'bold': 700
);

@each $name, $weight in $font-weight-map {
    .weight-#{$name}{
        font-weight: $weight;
    }
}

//fonts 

$font-map: (
	'poppins': 'Poppins, sans-serif',
	'franklin': 'Libre Franklin, sans-serif;'
);

@each $name, $font in $font-map {
	.font-#{$name} {
		font-family: unquote($font);
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@extend .weight-bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li {
    color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
footer .heading,
.read-more,
.view-cert,
.main-links a,
[data-dropdown-nav] .heading  {
    @extend .font-poppins;
}

body,
p,
a,
li {
    @extend .font-franklin;
}

a, 
.button {
    @extend .weight-semi-bold;
}

.button {
	font-size: rem-calc(17px);
	line-height: 20px;
}

.read-more,
.find-out-more,
.view-cert {
  @extend .color-theme,
          .weight-bold;
  
}

.find-out-more {
    @extend .uppercase;
}

// h1

h1,
.h1 {
    font-size: 2rem;
    line-height: rem-calc(42px);
    @include breakpoint(xlarge){
        font-size: 3rem;
        line-height: 58px;
    }
}
h2,
.h2 {
    font-size: 1.75rem;
    line-height: 38px;
    @include breakpoint(xlarge){
        font-size: 2.5rem;
        line-height: 50px;
    }
}

.post-heading {
    font-size: 1.35rem;
    line-height: 32px;
    @include breakpoint(xlarge){
        font-size: 2.5rem;
        line-height: 50px;
    }
}

h3,
.h3 {
    font-size: 1.5rem;
    line-height: 34px;
    @include breakpoint(xlarge){
        font-size: 2rem;
        line-height: 42px;
    }
}
h4,
.h4 {
    font-size: 1.25rem;
    line-height: 30px;
    @include breakpoint(xlarge){
        font-size: 1.5rem;
        line-height: 34px;
    }
}
h5,
.h5 {
    font-size: 1.125rem;
    line-height: 28px;
    @include breakpoint(xlarge){
        font-size: 1.25rem;
        line-height: 30px;
    }
}

h6,
.h6,
.intro {
    font-size: 1rem;
    line-height: 26px;
    @include breakpoint(xlarge){
        font-size: 1rem;
        line-height: 26px;
    }
}

p,
a,
span,
li {
    font-size: 0.9375rem;
    line-height: 28px;
    @include breakpoint(medium){
        font-size: 1rem;
        line-height: 30px;
    }
}

// h6,
// .intro {
//     font-size: 1.2rem;
//     line-height: 26px;
// }

// span,
// li,
// p,
// a {
//     font-size: 15px;
//     line-height: 30px;
// }

// @include breakpoint(large) {
//     h1 {
//         font-size: 3rem;
//         line-height: 55px;
//     }

//     h2 {

//     }

//     h3 {

//     }

//     h4 {

//     }

//     h5 {

//     }

//     h6,
//     .intro {
//         font-size: 1.4rem;
//         line-height: 32px;
//     }

//     span,
//     li,
//     p,
//     a {
//         font-size: 15.5px;
//         line-height: 34px;
//     }
// }

// @include breakpoint(xxlarge) {
//     h1 {
//         font-size: 4rem;
//         line-height: 74px;
//     }

//     h2 {
//         font-size: 48px;
//         line-height: 58px;
//         &.sub {
//             font-size: 24px;
//             line-height: 34px;
//         }
//     }

//     h3 {
//         font-size: 36px;
//         line-height: 46px;
//     }

//     h4 {
//         font-size: 24px;
//         line-height: 34px;
//     }

//     h5 {

//     }

//     h6,
//     .intro {
//         font-size: 1.5rem;
//         line-height: 34px;
//     }

//     span,
//     li,
//     p,
//     a {
//         font-size: 16px;
//         line-height: 36px;
//     }
// }

.content {
    &:not(.color-black) {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $theme;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 25px;
    }
    ul {
        @extend .stack,
                .bullets;
    }
    a {
        color: $theme-dark!important;
    }
}
//Contact

.contact {
    @extend .bg-white;
    .info,
    form {
        @extend .vert-padding-s,
                .horz-padding-m;
    }
    .thanks {
        @extend .vert-padding-xs;
    }
    form {
        @extend .bg-off-white;
        box-shadow: 0 10px 20px 0 rgba($black,0.2);
        border: 2px solid $theme;
        input[type="text"],
        input[type="email"],
        textarea {
            border: 2px solid $grey-light;
            box-shadow: none;
            @extend .horz-padding-xs;
        }
        textarea {
            @extend .vert-padding-xxs;
            height: 200px;
        }
        button[type="submit"]{
            background-color: #fdfdfd;
        }
    }
    .info {
        ul {
            @extend .stack,
                    .margin-xs;
        }
    }
    .net {
        display: none;
    }
}

#mailchimp_form {
    display: none;
}

#thanks {
    display: none;
    &.hidden {
        opacity: 0;
    }
}
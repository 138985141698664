//Sliders

.hide-dots {
    .slick-dots {
        display: none;
    }
}

[data-slick-block] {
    @extend .relative;
    .slick-dots {
        @extend .absolute;
        z-index: 45;
        left: 50%;
        bottom: 12px;
        @include breakpoint(large) {
            bottom: 45px;
        }
        @include transforms('translateX(-50%)')
        @include transitions('background-color 0.3s');
        li {
            cursor: pointer;
            margin: 0 $inline-space;
            padding: 5px;
            border-radius: 50%;
            background-color: rgba($white, 0.8);
            font-size: 0;
            line-height: 0;
            &.slick-active {
                background-color: rgba($theme, 0.8);
            }
        }
    }
    .prev-btn,
    .next-btn {
        z-index: 50;
        display: none;
        outline: none;
        @extend .absolute-center-vert,
                .color-white,
                .weight-bold;
        @include breakpoint(medium){
            display: block;
            line-height: $icon-height-large;
            height: $icon-height-large;
            width: $icon-width-large;
            background-color: $theme;
        }
    }
    .prev-btn {
        // left: -30px;
            left: 0px;
            h4 {
                margin-bottom: 0;
            }
        @include breakpoint(large){
        }
    }
    .next-btn {
        // right: -30px;
            right: 0px;
            h4 {
                margin-bottom: 0;
            }
        @include breakpoint(large){
        }
    }
}

[data-slick-slides] {
    &.row {
        margin-left: auto;
        margin-right: auto;
    }
    @extend .overflow-hidden;
    .slide {
        outline: none;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        @extend .relative,
                .bg-cover,
                .float-left;
    }
    .call-to-action {
        @extend .display-block,
                .text-center,
                .margin-t-xs;
    }
    .video {
        // @extend .absolute,
        //         .display-block;
        // @include scale(min-height: 750);
        @include positions(0,0,0,0);
        iframe {
            height: 100%;
            width: 100%;
        }
    }
    .popup {
        @extend .absolute;
        @include positions(0,0,0,0);
    }
}

.slide-info {
    width: 100%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    @extend .margin-s;
    .page-heading {
        .heading {
            @extend .uppercase;
        }
    }
}

//Element Spacing

$spacing-map: (
    'none': 0,
    'xxs': 20,
    'xs': 30,
    's': 50,
    'm': 90,
    'l': 120,
    'xl': 225,
    'xxl': 300
);

@each $name, $size in $spacing-map {

    $equation: calc(#{$size / 1920 * 100vw});
    
    .padding-#{$name}{
        padding-top: $equation;
        padding-bottom: $equation;
        padding-left: $equation;
        padding-right: $equation;
    }
    .vert-padding-#{$name}{
        padding-top: $equation;
        padding-bottom: $equation;
    }
    .horz-padding-#{$name}{
        padding-left: $equation;
        padding-right: $equation;
    }
    .vert-margin-#{$name}{
        margin-top: $equation;
        margin-bottom: $equation;
    }
    .margin-#{$name}{
        margin-bottom: $equation;
    }
    .margin-t-#{$name}{
        margin-top: $equation;
    }
}

.margin-gutter {
    margin-bottom: 1.25rem;
    @include breakpoint(medium){
        margin-bottom: 1.875rem;
    }
}

// @mixin vertPadding($size){
//     $calculation: calc(#{$size / 1920 * 100vw});
//     padding-top: $calculation;
//     padding-bottom: $calculation;
// }

@mixin scale($style, $size){
    $calculation: calc(#{$size / 1920 * 100vw});
    #{$style}: $calculation;
}
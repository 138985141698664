// --- Variables ---

//colours
$transparent: rgba(255, 255, 255, 0);
$white: #fff;
$off-white: #FAFAFA;
$black: #141414;
$theme: #CBD84A;
$theme-dark: darken($theme, 10%);
$accent: #3C6E71;

$grey-dark: #6D6E71;
$grey-mid: #d9d9d9;
$grey-light: #E4ECEC;

$inline-space: 8px;
$icon-height: 16px;
$icon-width: 16px;
$icon-height-large: 40px;
$icon-width-large: 40px;

// --- Mixins ---

//Colors

$color-map: (
    'transparent': $transparent,
    'white': $white,
    'off-white': $off-white,
    'black': $black,
    'grey-dark': $grey-dark,
    'grey-mid': $grey-mid,
    'grey-light': $grey-light,
    'theme': $theme,
    'theme-dark': $theme-dark,
    'accent': $accent
);

@each $name, $color in $color-map {
    .bg-#{$name}{
        background-color: $color;
    }
    .color-#{$name}{
        color: $color;
        a:hover,
        a:focus,
        &:hover,
        &:focus {
            color: $color;
        }
    }
}

// a:hover {
//     color: inherit!important;
// }

//Top, Right, Bottom, Left Positions

@mixin positions ($top, $right, $bottom, $left) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

//Transforms

@mixin transforms ($transform) {
    transform: unquote($transform);
    -webkit-transform: unquote($transform);
    -moz-transform: unquote($transform);
    -o-transform: unquote($transform);
    -ms-transform: unquote($transform);
}

//Transitions

@mixin transitions ($transition) {
    transition: unquote($transition);
    -webkit-transition: unquote($transition);
    -moz-transition: unquote($transition);
    -o-transition: unquote($transition);
    -ms-transition: unquote($transition);
}

// Global
* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

[data-interchange] {
    background-position: center;
    background-size: cover;
}

html,body{
    height: 100%;
    width: 100%;
    // overflow-x: hidden;
    // overflow: auto;
    -webkit-overflow-scrolling: auto;
    &.no-scroll {
        overflow: hidden;
        @include breakpoint(medium){
            overflow: auto;
        }
    }
}

//Common

.row {
    max-width: 420px;
    @include breakpoint(medium){
        max-width: 600px; 
    }
    @include breakpoint(large){
        max-width: 680px; 
    }
    @include breakpoint(xlarge){
        max-width: 768px; 
    }
    @include breakpoint(xxlarge){
        max-width: 960px; 
    }
    @include breakpoint(xxxlarge){
        max-width: 1280px; 
    }
    &.explode {
        max-width: none!important;
    }
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

.row-collapse {
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

.row-gutters {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    @include breakpoint(medium){
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Helpers

hr {
    border: 1px solid $grey-light;
}

ul,
ul ul {
    margin: 0;
    list-style: none;
    li {
        display: inline-block;
    }
    &.stack {
        li {
            display: block;
        }
    }
    &.bullets {
        li {
            @extend .relative;
            padding-left: 16px;
            &:before {
                content: "• ";
                color: inherit;
                top: 0;
                left: 0;
                @extend .display-inline-block,
                        .absolute,
                        .weight-bold;
            }
        }
    }
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.italic {
    font-style: italic;
}

.display-block {
    display: block;
}

.display-inline {
    display: inline;
}

.display-inline-block {
    display: inline-block;
}

.relative{
  position: relative;
}

.absolute {
    position: absolute;
}

.overflow-hidden {
    overflow: hidden;
}
.overflow-visible {
    overflow: visible;
}

.absolute-center {
    @extend .absolute;
    top: 50%;
    left: 50%;
    @include transforms("translate(-50%,-50%)");
}

.absolute-center-vert {
    @extend .absolute;
    top: 50%;
    @include transforms("translateY(-50%)");
}

.bg-contain {
    background: center center / contain no-repeat;
    background-size: contain!important;
}

.bg-icon {
    background: center bottom / contain no-repeat;
    background-size: 40%!important;
}

.bg-cover {
    background: center center / cover no-repeat;
}

a {
    &.cover {
        @extend .absolute;
        @include positions(0,0,0,0);
    }
}

img {
    &.cover {
        @extend .absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        @include transforms("translate(-50%,-50%)");
    }
}

.align-content {
    display: table;
    height: 100%;
    width: 100%;
    .vertical-align {
        display: table-cell;
        vertical-align: middle;
    }
}

.text-shadow {
    text-shadow: 0 0 20px rgba($black, 0.6);
}

.shadow {
    box-shadow: 0 0 20px 0 rgba($black,0.1);
}

.shadow-small {
    box-shadow: 0 0 5px 0 rgba($black,0.2);
}

.full-width {
    @extend .display-block;
    width: 100%;
    max-width: none;
}

article {
    &#header {
        border-bottom: 8px solid $theme;
    }
    &:nth-child(even) {
        @extend .bg-off-white;
    }
}


#header {
    @extend .text-center;
    .page-heading {
        @extend .margin-xs,
                .display-inline-block;
        background-color: rgba($theme, 0.8);
        .heading {
            @include scale(padding-top, 30);
            @include scale(padding-bottom, 30);
            @extend .horz-padding-s,
                    .color-white,
                    .margin-none;
            @include breakpoint(large){
                min-width: 588px;
            }
        }
    }
    .subline {
        @extend .weight-bold,
                .display-block,
                .color-white;
    }
}

.column-offset {
    // overflow: hidden;
    @include breakpoint(large){
        overflow: visible;
    }
    position: relative;
    min-height: 400px;
    .offset-left,
    .offset-right {
        @extend .absolute;
        top: 50%;
        right: 50%;
        min-height: 100%;
        min-width: 100vw;
        @include transforms("translate(50%, -50%)");
        @include breakpoint(large){
            min-width: calc((100vw / 12) * 7);
        }
    }
    .offset-left {
        @include breakpoint(large){
            right: 0;
            @include transforms("translate(0, -50%)");
        }
    }
    .offset-right {
        @include breakpoint(large){
            left: 0;
            @include transforms("translate(0, 50%)");
        }
    }
}

.content {
    p + h1,
    p + h2,
    p + h3,
    p + h4,
    p + h5,
    p + h6 {
        margin-top: 30px;
    }
}
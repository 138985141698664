//Icons

// .icon-before {
//     &:before {
//         content: '';
//         display: inline-block;
//         height: $icon-height;
//         width: $icon-width;
//         background-color: $grey-mid;
//         vertical-align: middle;
//         margin-right: $inline-space;
//     }
// }

// .icon-after {
//     &:after {
//         content: '';
//         display: inline-block;
//         height: $icon-height;
//         width: $icon-width;
//         background-color: $grey-mid;
//         vertical-align: middle;
//         margin-left: $inline-space;
//     }
// }

.icon-before {
    padding-left: #{$icon-width + $inline-space};
    background: left center / contain no-repeat;
}

.icon-after {
    padding-right: #{$icon-width + $inline-space};
    background: right center / contain no-repeat;
}
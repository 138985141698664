//Nav 
$navHeight-mobile: 30px;
$navHeight-laptop: 45px;
$navHeight: 60px;

#main_header {
}
.header-push {
    height: calc(#{$navHeight-mobile} + (60 / 1920 * 100vw));
    @include breakpoint(medium){
        height: calc(#{$navHeight-laptop} + (60 / 1920 * 100vw));
    }
    @include breakpoint(xxxlarge){
        height: calc(#{$navHeight} + (60 / 1920 * 100vw));
    }
    // @include breakpoint(xlarge){
    //     min-height: 100px;
    // }
    // @include breakpoint(xxxlarge){
    //     min-height: 115px;
    // }
}

.logo {
    height: $navHeight-mobile;
    // margin-top: 15px;
    @include breakpoint(medium){
        height: $navHeight-laptop;
    }
    @include breakpoint(xxxlarge){
        height: $navHeight;
        margin-top: 0;
    }
}

#nav {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: solid 4px $theme;
    height: auto!important;
    @extend .vert-padding-xs,
            .bg-off-white;
    nav.row {
        height: $navHeight-mobile;
        max-width: 1600px;
        @include breakpoint(medium){
            height: $navHeight-laptop;
        }
        @include breakpoint(xxxlarge){
            height: $navHeight;
        }
    } 
    a {
        font-size: rem-calc(15px);
        @include transitions('color 0.3s');
        &:hover,
        &.active {
            @extend .color-theme-dark;
        }
    }
}

.nav-container {
    width: 100%;
    @extend .show-for-xxlarge;
    &.show {
        display: block!important;
        background-color: $off-white;
    }
    ul,
    li {
        width: 100%;
        @extend .display-block,
                .text-center;
    }
    @include breakpoint(large){
        ul,
        li {
            width: auto;
            text-align: left;
            display: inline-block;
        }
    }
    @include breakpoint(xxlarge){
        float: right;
        width: auto;
    }
}

.hamburger {
    @extend .hide-for-xxlarge,
            .display-block,
            .absolute;
    top: 50%;
    right: 1rem;
    @include transforms('translateY(-50%)');
    height: 1.5rem;
    width: 2rem;
    li {
        @extend .display-block, .absolute;
        height: 4px; 
        width: 100%;
        background-color: $theme;
        &:first-child {
            top: 0;
        }
        &:nth-child(2) {
            top: 50%;
            @include transforms('translateY(-50%)');
        }
        &:last-child {
            bottom: 0;
        }
    }
}


.links li,
.links a,
.highlight {
    line-height: $navHeight;
    margin-bottom: 0;
    @include breakpoint(medium){
        line-height: $navHeight-laptop;
    }
    @include breakpoint(xxxlarge){
        line-height: $navHeight;
    }
}

.links li {
    @extend .horz-padding-xxs;
}

.links li,
.highlight {
    @extend .horz-padding-xxs;
}


.highlight {
    display: none;
    @extend .shadow,
            .font-poppins,
            .float-right,
            .weight-bold,
            .bg-theme,
            .color-white,
            .horz-padding-xs;
    font-size: rem-calc(17px);
    @include breakpoint(large) {
        position: absolute;
        top: 0;
        right: 80px;
        display: inline-block;
        margin-left: calc((30 / 1920) * 100vw);
    }
    @include breakpoint(xxlarge) {
        position: relative;
        right: 0;
        float: right!important;
    }
    a {
        color: $white!important;
    }
    // @extend .vert-padding-xxs;
}

[data-dropdown-link] {
    display: none;
    position: relative;
    &:after {
        content: '';
        display: none;
        height: $icon-height;
        width: $icon-width;
        background: url('../img/icons/dropdown.svg') center 20% / 70% no-repeat;
        vertical-align: middle;
        margin-left: $inline-space;
        @include transitions('background 0.3s');
    }
    &:hover {
        color: $theme-dark;
        &:after {
            background: url('../img/icons/dropdown-active.svg') center 20% / 70% no-repeat;
        }
    }
    [data-dropdown-nav] {
        &:before {
            content: '';
            @extend .vert-padding-xs,
                    .absolute,
                    .display-block;
            top: 0;
            width: 100%;
            @include transforms('translateY(-100%)');
        }
        @extend .margin-t-xs,
                .absolute,
                .bg-white,
                .row-gutters;
        display: none;
        left: 0;
        top: calc(30px + 1.5625vw);
        min-width: 320px;
        border: 1px solid $theme;
        // @include transforms('translateY(100%)');
        li {
            width: 100%;
            padding: 0;
            // @extend .row-gutters;
            a {
                display: block;
                height: 100%;
            }
        }
    }
    @include breakpoint(xlarge){
        &:after {
            display: inline-block;
        }
        &:hover {
            [data-dropdown-nav] {
                display: block;
            }
        }
    }
}

#header {
    .subline {
        @extend .text-shadow;
    }
}
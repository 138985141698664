//Home Page Specific

.home-page {
	#header {
		@extend .relative;
		.slide-info {
			user-select: none;
			@extend .absolute-center;
		}
		.slide .push {
			min-height: 580px;
			@include scale(height, 836);
		}
	}
}

.global-partners,
.sell-points {
	.column {
		@extend .margin-gutter;
	}
}

.global-partners {
	background-color: $white!important;
	@extend .text-center,
			.uppercase;

	.column {
		display: inline-block;
		margin-left: -4px;
		margin-right: -4px;
		float: none!important;
	}
	.heading {
		@extend .color-theme,
				.weight-bold;
	}
	.partners {
		border: 4px solid $theme;
		border-top: 0;
		border-radius: 0 0 50px 50px;
		@extend .shadow,
				.padding-s,
				.margin-m;
	}
}

.global-partners,
.sell-points {
	@extend .text-center;
	.wide {
		@media (max-width: 768px){
			padding-bottom: 30%;
			background-size: contain!important;
		}
	}
}

.sell-points,
.projects {
	.heading {
		@extend .color-black,
				.weight-bold,
				.uppercase,
				.margin-s;
	}
}

.projects {
	@extend .relative;
	.heading {
		@extend .text-center,
				.margin-t-s;
	}
	.project {
		.tile {
			padding-top: 100px;
			padding-bottom: 100px;
			@include breakpoint(xlarge){
				padding-top: 0;
				padding-bottom: 0;
			}
		}
		@include breakpoint(xlarge){
			width: percentage(1/3);
			&:nth-child(4n+1),
			&:nth-child(4n+2) {
				width: percentage(2/3);
			}
		}
	}
}

.services .service,
.structures .structure,
.equipment-section .equipment {
	.tile {
		padding-top: 100px;
		padding-bottom: 100px;
		@include breakpoint(xlarge){
			padding-top: 0;
			padding-bottom: 0;
		}
	}
	@include breakpoint(xlarge){
		width: percentage(1/3);
		&:nth-child(5n),
		&:nth-child(5n-1) {
			width: percentage(1/2);
		}
	}
}

.projects-page {
	.projects .project {
		@include breakpoint(xlarge){
			width: percentage(1/3);
			&:nth-child(4n),
			&:nth-child(4n+1) {
				width: percentage(2/3);
			}
		}
	}
}

// .equipment-section {
// 	.equipment {
// 		@include breakpoint(xlarge){
// 			width: percentage(1/3);
// 			&:nth-child(5n),
// 			&:nth-child(5n-1) {
// 				width: percentage(1/2);
// 			}
// 		}
// 	}
// }
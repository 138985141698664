//Single Page Template

[data-single-service][data-slick-block] {
	@extend .relative;

    .slide {
        @include scale(min-height, 830);
    }
    .expansion {
        visibility: hidden;
        .panel {
            @extend .display-block,
                    .padding-l;
        }
    }
    .slide-title {
        @extend .absolute,
                .text-left;
        padding: 10px 40px;
        bottom: 0;
        left: 0;
        background-color: $black;
        color: $theme;
        max-width: 550px;
        width: 100%;
        @include breakpoint(medium){
            width: auto;
        }
    }
    // .hide-show {
    //     @extend .display-block,
    //             .absolute;
    //     outline: none;
    //     height: $icon-height-large;
    //     width: $icon-width-large;
    //     bottom: 0;
    //     left: 0;
    //     background: $theme url('../img/icons/plus.png') center center / contain no-repeat;
    //     .title {
    //         display: none;
    //         @include breakpoint(large){
    //             display: block;
    //         }
    //     }
    //     // &.open {
    //     //     background: $theme url('../img/icons/cross.png') center center / contain no-repeat;
    //     //     .title {
    //     //         display: none;
    //     //     }
    //     // }
    //     &:not(.open){
    //         .title {
    //             @extend .absolute,
    //                     .bg-black,
    //                     .horz-padding-m,
    //                     .color-theme;
    //             right: 0;
    //             top: 0;
    //             height: 100%;
    //             width: auto;
    //             white-space: nowrap;
    //             line-height: $icon-height-large;
    //             @include transforms('translateX(100%)');
    //         } 
    //     }
    // }
    // .info {
    // 	@extend .absolute,
    // 			.display-block,
    // 			.color-white,
    // 			.text-left;
    //     z-index: 50;
    // 	@include positions(0,auto,0,0);
    // 	@include transforms('translateX(-100%)');
    // 	@include transitions('transform 0.4s');
    // 	background-color: rgba($black,0.9);
    // 	max-width: calc(100% - #{$icon-width-large});
    // 	&.open {
    // 		@include transforms('translateX(0%)');
    // 	}
    // 	.panel {
    // 		@extend .display-block,
    // 				.padding-l;
    // 	}
    	
    // }
    .pagination {
    	@extend .absolute-center-vert,
    			.display-block,
    			.color-white,
    			.bg-theme,
    			.padding-xxs,
    			.show-for-xlarge;
    	height: 70px;
    	width: 50px;
    	@include breakpoint(xxxlarge){
    		height: 70px;
    		width: 70px;
    	}
    	&:hover {
			.hover {
				display: block;
			}
    	}
    	.hover {
    		display: none;
    		@extend .absolute,
    				.bg-black,
    				.color-theme,
    				.horz-padding-xxs;
    		top: 0;
    		min-height: 100%;
    		width: auto;
            h1,h2,h3,h4,h5,h6 {
                line-height: 70px;
                margin-bottom: 0px;
            }
    		@include breakpoint(large){
    			white-space: nowrap;
    		}
    	}
        &.page-prev,
        &.page-next {
            z-index: 80;
        }
    	&.page-prev {
    		left: 0;
    		.hover {
    			right: 0;
    			@include transforms('translateX(100%)');
    		}
    	}
    	&.page-next {
    		right: 0;
    		.hover {
    			left: 0;
    			@include transforms('translateX(-100%)');
    		}
    	}
    	span {
    		font-size: 2rem;
    		@extend .display-block,
    				.absolute-center;
    		@include breakpoint(xxxlarge){
    			font-size: 3rem;
    		}
    	}
    }
}

// .service-types {
//     @extend .horz-padding-l;
// }

.service-intro,
.service-types,
.project-info {
	.section-heading {
		@extend .weight-semi-bold;
	}
	.heading {
		@extend .color-theme,
				.margin-xs;
	}
	.list-heading {
		@extend .margin-t-s;
	}
}

.service-types {
    .content {
        ul {
            @extend .vert-margin-s;
        }
    }
}

.service-intro,
.service-types {
	.service {
		@extend .margin-m,
				.clearfix;
	}
}

.project-details {
	.details {
		@extend .margin-s;
	}
	.heading {
		@extend .color-theme,
				.margin-xs;
	}
	p {
		@extend .margin-xxs;
	}
}

// #service_nav {
//     .next-heading {
//         color: $black;
//         &:hover {
//             color: $black;
//         }
//     }
//     .all-entries a,
//     .next-entry {
//         color: $grey-dark;
//         @include transitions('color 0.3s');
//         &:hover {
//             color: $theme;
//         }
//     }
//     .next-link {
//         display: block;
//         .next-entry {
//             @include transitions('margin-left 0.3s, color 0.3s');
//         }
//         &:hover {
//             .next-entry {
//                 color: $theme;
//                 margin-left: 15px;
//             }
//         }
//     }
// }

#service_subnav {
    @extend .text-center;
    display: none;
    overflow: hidden;
    padding: 5px 32px;
    background: lighten($black, 50%);
    position: fixed;
    top: calc(30px + (60 / 1920 * 100vw));
    left: 0;
    right: 0;
    z-index: 99;
    li {
        line-height: 20px;
        padding: 5px;
        overflow: hidden;
        white-space: nowrap;
        a {
            line-height: 20px;
            font-size: 0.7rem;
        }
    }
    .horz-scroll {
        .link {
            display: block;
            float: left;
        }
    }
    .slick-prev,
    .slick-next {
        @extend .absolute;
        top: 0;
        font-size: 0;
        outline: none;
    }
    .slick-prev {
        left: -32px;
        width: 32px;
        height: 100%;
        background: url('../img/icons/arrow-left.svg') right center / 25px no-repeat;
    }
    .slick-next {
        right: -32px;
        width: 32px;
        height: 100%;
        background: url('../img/icons/arrow-right.svg') left center / 25px no-repeat;
    }
    @include breakpoint(medium){
        display: block;
        top: calc(45px + (60 / 1920 * 100vw));
    }
    @include breakpoint(large){
        li {
            padding: 5px 10px;
            a {
                font-size: 0.85rem;
            }
        }
    }
    @include breakpoint(xxxlarge){
        top: calc(64px + (60 / 1920 * 100vw));
    }
    li {
        outline: none;
        a {
            color: $white;
            @include transitions('color 0.3s');
            &:hover {
                color: $theme;
            }
        }
        &.active {
            a {
                color: $theme;
            }
        }
    }
}